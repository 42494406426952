.txnsStatus {
  display: flex;
  height: 26px;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 4px;
  color: $white;
  text-transform: uppercase;
  justify-content: center;

  &.approved {
    background-color: $approved;
  }

  &.success {
    background-color: $approved;
  }

  &.failure {
    background-color: $failure;
  }

  &.invalid {
    background-color: $failure;
  }

  &.pending {
    background-color: $pending;
  }

}
