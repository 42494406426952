.amountBlock {
  display: grid;
  grid-template-columns: 1fr 1fr 1.6fr;
  padding: 14px 10px;
  margin-left: 15px;

  &-number {
    font-size: 60px;
    line-height: 47px;
    justify-self: center;
    align-self: end;
  }

  &-unit {
    font-size: 12px;
    margin-left: 5px;
    align-self: end;
    text-transform: uppercase;
    @include theme-aware('color', 'countBlockTextColor');
  }

  &-timeWrap {
    font-size: 12px;
    line-height: 16px;
    align-self: end;
    justify-self: end;
    text-align: end;
    text-transform: uppercase;
    @include theme-aware('color', 'countTextColor');

    &-timeAgo {
      font-weight: 700;
    }
  }
}

@media (max-width: 1150px) {
  .amountBlock {
    margin-left: unset;
    margin-top: 20px;
  }
}