.sidebarMenu {
  padding: 10px 0;
  margin-bottom: 10px;
  @include theme-aware('background-color', 'sideBarBackground');

  &-list {
    list-style: none;

    .item {
      font-size: 14px;
      text-transform: uppercase;
      padding: 3px 20px;

      &-link {
        display: flex;
        color: $menuGrey;

        .icon {
          margin-right: 5px;
            svg {
              fill: $menuGrey;
            }
        }
      }

      &-link:hover, .active {
        &.overview {
          color: $overviewColor;
          .icon {
            svg {
              fill: $overviewColor;
            }
          }
        }
        &.epochs {
          color: $epochColor;
          .icon {
            svg {
              fill: $epochColor;
            }
          }
        }
        &.layers {
          color: $layerColor;
          .icon {
            svg {
              fill: $layerColor;
            }
          }
        }
        &.txs {
          color: $transactionColor;
          .icon {
            svg {
              fill: $transactionColor;
            }
          }
        }
        &.rewards {
          color: $rewardsColor;
          .icon {
            svg {
              fill: $rewardsColor;
            }
          }
        }
        &.accounts {
          color: $accountsColor;
          .icon {
            svg {
              fill: $accountsColor;
            }
          }
        }
        &.apps {
          color: $smartWalletColor;
          .icon {
            svg {
              fill: $smartWalletColor;
            }
          }
        }
        &.smeshers {
          color: $smesherColor;
          .icon {
            svg {
              fill: $smesherColor;
            }
          }
        }
      }
    }
  }
}
