.navbar {
  display: flex;
  flex-wrap: wrap;

  &-list {
    display: flex;
    list-style: none;
    align-items: center;

    &-item {
      font-size: 16px;

      a {
        text-decoration: underline;
      }
    }

    &-item:not(:last-child):after {
      content: "//";
      display: inline-block;
      margin: 0 5px 0 5px;
      @include theme-aware('color', 'navBarSeparator');
    }
  }
}

@media (max-width: 1150px) {
  .navbar-list {
    flex-direction: column;
    align-items: start;
  }
}