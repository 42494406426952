.details {
  font-size: 16px;
  color: $textColor;
  @include theme-aware('color', 'detailsPageTextColorName');
  @include theme-aware('background-color', 'searchBackground');

  &-list {
    max-width: 100%;
    padding: 14px 18px 14px 24px;
    list-style: none;

    .item {
      padding: 10px 0;

      &.sliderDown {
        padding: 2px 0;
      }

      &-name {
        padding-right: 5px;
        @include theme-aware('background-color', 'searchBackground');
      }

      &-value {
        position: relative;
        @include theme-aware('color', 'detailsPageTextColorValue');
        color: $hashColor;
        float: right;
        padding-left: 5px;
        @include theme-aware('background-color', 'searchBackground');

        .dropDownLink {
          margin-right: 18px;
        }

        a {
          cursor: pointer;
          position: relative;

          .arrow {
            border: solid $layerColor;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 2px;
            height: 0;
            position: absolute;
            right: -14px;
            top: 7px;
            width: 0;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);

            &.isOpen {
              top: 9px;
              transform: rotate(-135deg);
              -webkit-transform: rotate(-135deg);
            }
          }
        }
      }
    }

    .item:before {
      float: left;
      width: 0;
      white-space: nowrap;
      content: "................................................." "................................................."
    }
  }
}

@media (max-width: 1150px) {
  .details {
    overflow-x: scroll;
    font-size: 13px;

    &-list {
      max-width: unset;
      width: 1200px;

      &-item {
        width: 1200px;
      }

      .item:before {
        float: left;
        width: 0;
        white-space: nowrap;
        content: ".................................................................................." ".................................................................."
      }
    }
  }
}