.rightColumn {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  padding: 15px 10px;
  margin-left: 15px;

  &-number {
    font-size: 60px;
    line-height: 47px;
    align-self: end;
    text-align: center;
  }

  &-desc {
    font-size: 12px;
    text-transform: uppercase;
    margin-left: 5px;
    align-self: end;
    @include theme-aware('color', 'countTextColor');
  }

  &-data {
    font-size: 12px;
    align-self: end;
    text-align: end;
    text-transform: uppercase;
    @include theme-aware('color', 'countTextColor');
  }
}

@media (max-width: 1150px) {
  .rightColumn {
    margin-left: unset;
    margin-top: 20px;
  }
}