.round {
  margin-top: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: $tile_title;

  &.green {
    background-color: $green;
  }

  &.red {
    background-color: $red;
  }

  &.orange {
    background-color: $orange;
  }
}
