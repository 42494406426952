.infoBlock {
  @include theme-aware('background-color', 'backgroundInfoBlock');
  margin-bottom: 15px;

  &-list {
    display: flex;
    justify-content: space-around;
    list-style: none;
  }

  li+li { border-left: 1px solid $secondGrey }

  &-item {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;

    &-number {
      color: $overviewColor;
      font-size: 20px;

      a {
        color: $overviewColor;
      }

      a:hover {
        text-decoration: underline;
      }
    }

    &-title {
      @include theme-aware('color', 'titleInfoBlock');
      color: #9A9A9A;
      font-size: 16px;
      text-transform: uppercase;
    }
  }
}

@media (max-width: 1150px) {
  .infoBlock {
    &-list {
      flex-direction: column;
    }

    &-item:first-child {
      align-items: center;
    }

    &-item:last-child {
      align-items: center;
    }
  }

  .infoBlock-item:not(:last-child) {
    border: none;
  }
}