// Colors HEX
$lightBackground: #F3F3F3;
$textColor: #9A9A9A;
$grey: #EEEEEE;
$secondGrey: #DDDDDD;
$sideBarGrey: #DBDBDB;
$darkBackground: #323031;
$searchColor: #404040;
$hashColor: #404040;
$menuGrey: #C4C4C4;
$lightGrey: #F3F3F3;
$searchBackgroundLight: #E9E9E9;
$searchBackgroundDark: #323031;
$countBlockTextColor: #89898B;

$preloaderTextColor70: rgba(154, 154, 154, 0.3);
$preloaderTextColor100: rgba(235, 235, 235, 1);

$white: #FFFFFF;
$dark: #252429;
$green: #65B042;
$red: #FF0000;
$orange: #FFA500;

// site main color
$overviewColor: #AA58B1;
$epochColor: #F79F53;
$layerColor: #1776E3;
$transactionColor: #65B042;
$rewardsColor: #24AEA6;
$accountsColor: #DF32AF;
$smesherColor: #F7D353;
$activationColor: #7B42F4;
$activationBgColor: #362A52;
$activationLightBgColor: #DBD0F3;
$blockColor: #1744E3;
$smartWalletColor: #DF3266;
$smesherBgColor: #4F4731;
$smesherLightBgColor: #F4EDD3;

// status color
$confirmed: #65B042;
$approved: #8EC376;
$processing: #AA58B1;
$pending: #BC83C1;
$conflicting: #F79F53;
$insufficientFunds: #F2B47F;
$failure: #EC5C3d;

$blue: #1776E3;
$tile_title_bottom_border: #797979;
$map_background: #c4c4c485;
$map_color: #5D5A6D;
$bar_chart: #C4C4C4;
$map_geographies_fill: #EAEAEC; // 3f3e40
$map_geographies_stroke: #D6D6DA;

$map_geographies_fill_dark: #3f3e40;
$map_geographies_stroke_dark: #3f3e40;
$q_icon_bg: #828282;
$browser_all: #828282;

$futureEpochDark: #c4c4c485;
$futureEpochLight: #9c9c9c;

// Colors RGBA
$tile_background: rgba(238, 238, 238, 1);
$tile_background_dark: #4D4C4F;

$page_background: #f3f3f3;
$page_background_dark: #252429;;

$tile_title: rgba(64, 64, 64, 0.5);
$tile_title_bottom_border: rgba(215, 215, 215, 1);

// Fonts
$main-font-family: 'NeubauGrotesk', sans-serif;
$code-pro-font-family: 'Source Code Pro', monospace;

// Pagination
$paginationBackgroundDark: #2A2928;
$paginationBackgroundLight: #E0E0E0;


:export {
  overviewColor: $overviewColor;
  overviewBgColor: rgba(223, 50, 175, 0.2);
  epochColor: $epochColor;
  epochBgColor: rgba(247, 159, 83, 0.2);
  layerColor: $layerColor;
  layerBgColor: rgba(23, 118, 227, 0.2);
  transactionColor: $transactionColor;
  transactionBgColor: rgba(101, 176, 66, 0.2);
  rewardsColor: $rewardsColor;
  rewardsBgColor: rgba(36, 174, 166, 0.2);
  accountsColor: $accountsColor;
  activationColor: $activationColor;
  activationBgColor: $activationBgColor;
  activationLightBgColor: $activationLightBgColor;
  accountsBgColor: rgba(223, 50, 175, 0.2);
  smesherColor: $smesherColor;
  smesherBgColor: $smesherBgColor;
  smesherLightBgColor: $smesherLightBgColor;
  blockColor: $blockColor;
  smartWalletColor: $smartWalletColor;
  smartWalletBgColor: rgba(223, 50, 102, 0.2);
  notFoundColor: #EC5C3D;
  notFoundBgColor: rgba(236, 92, 61, 0.2);
}
