.container {
  margin-top: 25px;
  max-width: 1200px;
}

.grid {
  color: white;
  display: flex;
}

.sidebar {
  flex: 1;
  margin-right: 12px;
}

.main {
  flex: 6;

  .browseAll-link {
    padding: 5px 0 0 35px;

    a {
      font-size: 14px;
      @include theme-aware('color', 'titleInfoBlock');
      text-decoration: underline;
    }

  }
}

@media (max-width: 1150px) {
  .grid {
    flex-direction: column;
  }

  .container {
    padding: 0 9px;
  }

  .main {
    margin-top: 15px;
  }

  .sidebar {
    margin-right: unset;
  }
}