.countBlock {
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr 2fr;
  padding: 14px 10px;
  margin-left: 15px;

  &-number {
    font-size: 60px;
    line-height: 47px;
    justify-self: center;
    align-self: end;
  }

  &-unit {
    font-size: 12px;
    align-self: end;
    text-transform: uppercase;
    @include theme-aware('color', 'countBlockTextColor');
  }

  &-badge {
    display: grid;
    align-self: end;
    justify-self: center;
    .bm {
      margin-bottom: -5px;
    }
  }

  &-time {
    font-size: 12px;
    line-height: 16px;
    align-self: end;
    justify-self: end;
    text-align: end;
    text-transform: uppercase;
    @include theme-aware('color', 'countTextColor');
  }
}

.countBlockLong {
  display: grid;
  grid-template-columns: 3fr 1fr;
  padding: 15px 10px;
  margin-left: 15px;

  &-value {
    .number {
      .value {
        font-size: 20px;
        line-height: 25px;
        justify-self: center;
        align-self: end;
        margin-right: 5px;
      }
      .unit {
        font-size: 12px;
        align-self: end;
        text-transform: uppercase;
        @include theme-aware('color', 'countBlockTextColor');
      }
    }

    .time {
      font-size: 14px;
      line-height: 18px;
      padding-top: 8px;
      text-align: left;
      text-transform: uppercase;
      @include theme-aware('color', 'countBlockTextColor');

      &-date {
        margin-right: 5px;
      }
    }
  }
  &-badge {
    display: grid;
    align-self: end;
    justify-self: center;
    .bm {
      margin-bottom: -5px;
    }
  }
}
