.page-wrap {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  padding-bottom: 20px;

  &-search-not-found {
    grid-template-columns: unset;
  }
}

@media (max-width: 1150px) {
  .page-wrap {
    grid-template-columns: unset;
  }
}
