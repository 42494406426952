.timeBlock {
  color: $dark;
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 15px;
  @include theme-aware('background-color', 'backgroundInfoBlock');

  &-item {
    .name {
      font-size: 14px;
      @include theme-aware('color', 'titleInfoBlock');
    }
    .value {
      font-size: 14px;
      color: $layerColor
    }
  }
  &-item:nth-child(2) {
    padding-top: 5px;
  }
}
